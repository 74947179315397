import React from "react"
import Container from "../components/container"
import Layout from "../components/layout"
import img from "../images/karta.svg"
import Link from "../components/link"
import HittaHit from "../components/hitta-hit"

export default function HittaHitPage() {
  return (
    <Layout>
      <HittaHit />
    </Layout>
  )
}
